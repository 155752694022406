<template>
    <div>
        <div @click="btncontent">
            <div v-for="(item,index) in chatnewslist" :key="item.id" class="chat-news">

                <div class="group-space-between">
                    <div>
                        <div class="comment-user">
                            <img class="headImg" :src="item.head_pic">
                            <span>{{item.nickname}}</span>
                        </div>
                        <div class="comment-content" @click="btndetail(item.t_id)">
                            <span v-if="item.c_id!=0" class="comment-time">回复了你的评论</span>
                            <span v-if="item.c_id==0" class="comment-time">评论了你的笔记</span>
                            <span class="comment-time" style="margin-left:10px">{{item.add_time|dateFormat}}</span>
                        </div>
                        <div class="comment-content" @click="btndetail(item.t_id)">
                            <div style="margin-bottom:10px">
                                <span class="comment-time">∎{{item.toinfo|ellipsis}}</span>
                            </div>
                        </div>
                        <div>
                            <span class="comment-reply1 " @click.stop="btnchatinput(item.t_id,item.id,item.c_id,index)">回复评论</span>
                        </div>
                    </div>
                    <div @click="btndetail(item.t_id)">                        
                        <img class="topicImg" :src="item.img">
                    </div>
                </div> 
            </div>
        </div>
        <chat-input @btnsend="btnsend" id="chat-input" class="chat-input" @btnchatinput="btnchatinput" ></chat-input>

        <component v-for="(item,index) in comName"
                   @btnoutother="btnoutother(index)"
                   class="user-other" id="user-other"
                   :key="item.id" :is="item.name"
                   :userlist="userlist"
                   :user_id="userlist.id"></component>
    </div>
</template>

<script>
    import ChatInput from "./ChatInput";
    import UserOther from "../../../user/userother/UserOther";
    import {addComment} from "network/home";
    export default {
        name: "Chatcom",
        components:{
            ChatInput,
            UserOther
        },
        data(){
            return{
                comName:[],
                tid:null,
                id:null,
                newIndex:0,
            }
        },
        props:{
            chatnewslist:{
                type:Array
            },
            userId:{
                type:[String,Number]
            },
            userlist:{
                type:Object
            }
        },methods:{
            btnchatinput(tid,id,cid,index){
                this.tid = tid;
                this.id = cid==0?id:cid;
                this.index = index;
                let chatinput=document.getElementById("chat-input")
                chatinput.style.bottom=0+"px"
            },

            btncontent(){
                let chatinput=document.getElementById("chat-input")
                chatinput.style.bottom=-190+"px"
            },
            btnnewuser(){
               /* 创建组件*/
                this.comName.push({
                    name:"UserOther"
                })
            },
            btnsend(content){
                var that = this;
                addComment(that.tid,that.id,content).then(res=>{
                    if(res.data.status==1){
                        this.chatnewslist.splice(that.index,1)
                        let chatinput=document.getElementById("chat-input")
                        chatinput.style.bottom=-190+"px"
                    }else{
                        this.$message('网络错误，请重新提交!');
                    }
                })
            },
            btnoutother(index){
                /*删除组件*/
                this.comName.splice(index, 1);
                let userother=document.getElementById("user-other");
                userother.style.left=100+"%"
                let noteother=document.getElementById("note-other")
                let fabsother=document.getElementById("fabs-other")
                noteother.style.display='none'
                fabsother.style.display='none'
            },
            btndetail(cid){
                this.$router.push('/homedetail/'+cid)
            },
        }
    }
</script>

<style scoped>
    .chat-news{
        padding: 15px 10px;
        height: 100%;
        border-bottom:1px solid #f4f4f4
    }
    .headImg{
        width: 35px;
        height: 35px;
        border-radius: 50%;
    }
    .topicImg{
        width: 60px;
        height: 60px;
        border-radius: 10%;
    }
    .chat-news-sen{
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .chat-news-sen span{
        margin-right: 10px;
        font-size: 13px;
        background-color: rgb(100,110,240);
        padding: 8px 12px;
        color:rgb(245,245,245);
        border-radius: 10px;
        box-shadow: 0 0 3px rgb(120,120,120);
    }
    .chat-news-rec{
        display: flex;
        align-items: center;
    }
    .chat-news-rec span{
        margin-left: 10px;
        font-size: 13px;
        background-color: #fff;
        padding: 8px 12px;
        color: #000;
        border-radius: 10px;
        box-shadow: 0 0 3px rgb(120,120,120);
    }
    .chat-input{
        position: fixed;
        right: 0;
        left: 0;
        bottom: -190px;
        border-top: 0.001rem solid rgba(150,150,150,.2);
        background-color: #fff;
        transition: all 0.5s;
    }
    .user-other{
        position: fixed;
        height: 100vh;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 20;
        transition: all 0.5s;
    }

    .comment-user{
        display: flex;
        align-items: center;
        margin-bottom: 8px;
    }
    .comment-user span{
        margin-left: 8px;
        font-size: 14px;
    }

    .comment-content{
        margin-left: 45px;
    }
    .comment-content-com{
        font-size: 14px;
        margin-right: 5px;
    }
    .comment-time{
        font-size: 12px;
        color: rgb(150,150,150);
    }
    .comment-reply1{
        background-color:#f1f1f1;
        padding: 5px 15px 5px 15px;
        border-radius: 20px;
        font-size: 12px;
        color: rgb(100,100,100);
        margin:0 0 10px 45px;
    }
</style>
<template>
  <div class="chat-list">
    <!--头部导航-->
    <div class="chat-nav">
      <i class="fas fa-angle-left" @click="btnchatleft"></i>
      <div class="chat-nav-head">
        <span>收到的评论</span>
        <!-- <img :src="userlist.head">
                <span>{{userlist.nickname}}</span> -->
      </div>
    </div>

    <!--中部内容-->
    <div class="chat-content" id="chat-content">
      <chatcom @btnsend="btnsend" :userId="userId" :userlist="userlist" :chatnewslist="chatnewslist"></chatcom>
    </div>
  </div>
</template>

<script>
import Chatcom from './Chatcom'
import { queryMyCommentList } from 'network/new'
export default {
  name: 'Chat',
  components: {
    Chatcom
  },
  data() {
    return {
      chatnewslist: [],
      userId: null,
      user_id: null,
      userlist: {},
      pageIndex: 1,
      totalPages: 1
    }
  },
  created() {
    this.userId = window.sessionStorage.getItem('userId')
    this.user_id = this.$route.params.id

    /*拿到用户信息*/
    // this.getfindUser()
    this.queryMyCommentList()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    btnchatleft() {
      this.chatnewslist = []
      this.$router.push('/news')
    },

    btnsend() {
      this.$router.go(0)
    },

    getfindUser() {},
    /*获取聊天内容*/
    queryMyCommentList() {
      var that = this
      queryMyCommentList(that.pageIndex).then((res) => {
        that.chatnewslist = that.chatnewslist.concat(res.data.result)
        that.totalPages = res.data.page.totalPages
        that.pageIndex = res.data.page.nowPage + 1
      })
    },
    handleScroll() {
      //这里使用个延时加载，不然滑动屏幕的时候会不断触发方法，去计算高度，浪费性能
      let that = this
      let scrollTop
      clearTimeout(this.timer)
      that.timer = setTimeout(function() {
        scrollTop = document.documentElement.scrollTop || document.body.scrollTop
        let windowHeight = document.documentElement.clientHeight || document.body.clientHeight
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight
        //获取到关键属性的值后，判断即可
        if (scrollTop + windowHeight >= scrollHeight) {
          var pageIndex = that.pageIndex
          if (pageIndex <= that.totalPages) {
            queryMyCommentList(that.pageIndex).then((res) => {
              that.chatnewslist = that.chatnewslist.concat(res.data.result)
              that.totalPages = res.data.page.totalPages
              that.pageIndex = res.data.page.nowPage + 1
            })
          }
        }
      }, 500)
    }
  }
}
</script>

<style scoped>
.chat-list {
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  position: relative;
  z-index: 5;
}
.chat-nav {
  height: 39px;
  width: 100%;
  display: flex;
  box-shadow: 0 0 5px rgba(120, 120, 120, 0.5);
  align-items: center;
  text-align: center;
  background-color: #fff;
}
.chat-nav i {
  margin-left: 10px;
  font-size: 20px;
}
.chat-nav img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.chat-nav-head {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat-nav-head span {
  margin-left: 3px;
  font-size: 14px;
}
.chat-content {
  height: 95vh;
  width: 100%;
  overflow-y: auto;
}
.chat-content::-webkit-scrollbar {
  display: none;
}
</style>

<template>
    <div>
        <div class="input-bar">
            <div class="input-box">
                <input v-model="news" placeholder="发消息...">
            </div>
            <div class="input-span">
                <span @click="btnsend">发送</span>
            </div>
        </div>

        <div id="bottom-input" class="bottom-input"></div>
    </div>
</template>

<script>
    export default {
        name: "ChatInput",
        data(){
            return{
                news:''
            }
        },
        methods:{
            btnchatinput(){
                /*let bottominput=document.getElementById("bottom-input")
                bottominput.style.display="block"*/
                this.$emit("btnchatinput")
            },
            btnsend(){
                this.$emit("btnsend",this.news)
            }
        }
    }
</script>

<style scoped>
    .input-bar{
        width: 100%;
        height: 39px;
        background-color: #fff;
        display: flex;
        align-items: center;
        text-align: center;
    }
    .input-box{
        flex: 1;
        margin-left: 10px;
    }
    .input-bar input{
        border: 0;
        background-color: rgba(220,220,220,.6);
        /*光标颜色*/
        caret-color: #1bc4fb;
        /*选中后*/
        outline: 0;
        color: #000;
        /*高度*/
        padding: 8px;
        width: 95%;
        border-radius: 20px;
        font-size: 13px;
        margin-right: 6px;
    }
    .input-span{
        width: 18%;
    }
    .input-span span{
        background-color: rgb(100,110,240);
        padding: 5px 12px;
        border-radius: 10px;
        font-size: 14px;
        color: rgb(240,240,240);
    }
    .bottom-input{
        height: 150px;
        width: 100%;
    }
</style>